define("ember-hash-helper-polyfill/helpers/hash", ["exports"], function (exports) {
  "use strict";

  exports.hash = hash;

  function hash(_, obj) {
    return obj;
  }

  exports["default"] = Ember.Helper.helper(hash);
});