define('ember-one-way-select/index', ['exports', 'ember-one-way-select/components/one-way-select', 'ember-one-way-select/components/one-way-input'], function (exports, _emberOneWaySelectComponentsOneWaySelect, _emberOneWaySelectComponentsOneWayInput) {
  'use strict';

  Object.defineProperty(exports, 'OneWaySelect', {
    enumerable: true,
    get: function get() {
      return _emberOneWaySelectComponentsOneWaySelect['default'];
    }
  });
  Object.defineProperty(exports, 'OneWayInput', {
    enumerable: true,
    get: function get() {
      return _emberOneWaySelectComponentsOneWayInput['default'];
    }
  });
});