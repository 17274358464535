define('ember-simple-auth-token/utils/load-config', ['exports'], function (exports) {
  'use strict';

  exports['default'] = function (defaults) {
    return function (container, config) {
      var wrappedConfig = Ember.Object.create(config);

      for (var property in this) {
        if (this.hasOwnProperty(property) && Ember.typeOf(this[property]) !== 'function') {
          this[property] = wrappedConfig.getWithDefault(property, defaults[property]);
        }
      }
    };
  };
});